import { navigate, Link } from "gatsby";
import React, { useState } from "react";
import {
  RealmAppProvider,
  useRealmApp,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";
import OnboardingRedirector from "../../../components/facilitator/onboarding_redirector";
import WelcomeHeader from "../../../components/welcome_header";
import PrevNextButtons from "../../../components/prev_next";

const NameCirclePage = () => {
  const [loading, setLoading] = useState("");
  const [circleName, setCircleName] = useState("");
  const realmApp = useRealmApp();

  function validateName(name) {
    if (name.length == 0) {
      throw "Please give your circle a name. This is the name your members will see!";
    }
  }

  async function handleNameCircle() {
    setLoading("saving...");

    var validated = true;
    try {
      validateName(circleName);
    } catch (err) {
      console.log(err);
      alert(err);
      validated = false;
    }

    if (validated) {
      console.log(["currentUser", realmApp.app]);
      try {
        var result = await realmApp.app.currentUser.functions.nameCircle(
          circleName
        );
        console.log(result);
        if ("modifiedCount" in result && result["modifiedCount"] == 1) {
          // refresh to make sure user data registers as having named the circle
          await realmApp.appRefreshCustomData();
          navigate("/facilitator/signup/congrats/");
        }
      } catch (err) {
        alert(
          "error encountered. Please try again or contact our support " +
            "team with the following output: " +
            JSON.stringify(err)
        );
      }
    }

    setLoading("");
  }

  const onboarding =
    new URL(location.href).searchParams.get("state") == "onboarding";
  console.log(`name_circle onboarding: ${onboarding}`);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <WelcomeHeader link={true} suffix="Facilitator Portal" />
      <div>Awesome! What do you want to call your circle?</div>
      <div>(You can always change it later.)</div>
      <br />
      <div>
        Circle Name:
        <br />
        <input
          type="text"
          value={circleName}
          onChange={(e) => setCircleName(e.target.value)}
        />
      </div>
      <br />
      <PrevNextButtons
        hasPrev={true}
        onClickPrev={
          onboarding
            ? () => {
                console.log("clicked!");
                navigate(
                  "/facilitator/signup/profile_picture/?state=onboarding"
                );
              }
            : () => {
                console.log("clocked!");
                navigate("/facilitator/dashboard");
              }
        }
        hasNext={true}
        onClickNext={handleNameCircle}
      />
      {loading}
    </div>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <NameCirclePage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
